<template>
  <a-row>
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ $route.meta.title[$i18n.locale] }}
      </h2>
      <a-row class="flex-no__wrap" type="flex">
        <a-button type="primary" @click="modalVisible = true">
          <a-icon type="plus" />{{ $t("Add") }}</a-button
        >
      </a-row>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="list"
      :loading="loading"
      :pagination="false"
      :row-key="(record) => record.id"
    >
      <template slot="id" slot-scope="item">
        <b>{{ item.id }}</b>
      </template>
      <template slot="title" slot-scope="item">
        {{ item.title }}
      </template>
      <template slot="category" slot-scope="item">
        {{ findCategory(item.category) }}
      </template>
      <template slot="value" slot-scope="item">
        {{ item.value || item.text }}
      </template>
      <template slot="operation" slot-scope="item">
        <a-row class="btns" type="flex" align="middle" justify="end">
          <a-button type="primary" class="edit-btn" @click="updateItem(item.id)">
            <a-icon type="edit" class="action-btns" />
          </a-button>
          <a-popconfirm
            :cancel-text="$t('No')"
            :ok-text="$t('Yes')"
            :title="$t('APopconfirm')"
            @confirm="removeItem(item.id)"
          >
            <a-button type="primary">
              <a-icon class="action-btns" type="delete" />
            </a-button>
          </a-popconfirm>
        </a-row>
      </template>
    </a-table>
    <a-modal :visible="modalVisible" :title="$t('Add')" :closable="false" width="600px">
      <a-row align="middle" class="flex-baseline" style="margin-bottom: 15px">
        <a-col :span="4" style="text-align: right; padding-right: 8px">
          {{ $t("Lang") }}:
        </a-col>
        <a-col :span="20">
          <a-row align="middle" class="flex-baseline flex-wrap">
            <lang-tabs v-model="langTab" class="mb-1x" />
          </a-row>
        </a-col>
      </a-row>
      <a-row align="middle" class="flex-baseline" style="margin-bottom: 15px">
        <a-col :span="4" style="text-align: right; padding-right: 8px">
          {{ $t("Type") }}:
        </a-col>
        <a-col :span="20">
          <a-row align="middle" class="flex-baseline flex-wrap">
            <a-select
              v-model="form.category"
              :placeholder="$t('Select')"
              style="width: 100%"
              @change="onChangeCategory"
            >
              <a-select-option
                v-for="item in categoryList"
                :key="item.id"
                :value="item.category"
              >
                {{ item.name && item.name[$i18n.locale] }}
              </a-select-option>
            </a-select>
          </a-row>
        </a-col>
      </a-row>
      <a-row align="middle" class="flex-baseline" style="margin-bottom: 15px">
        <a-col :span="4" style="text-align: right; padding-right: 8px">
          {{ $t("TagsColTitle") }}:
        </a-col>
        <a-col :span="20">
          <a-row align="middle" class="flex-baseline flex-wrap">
            <a-input v-model="form.title[getKey]" :placeholder="$t('TagsColTitle')" />
          </a-row>
        </a-col>
      </a-row>
      <a-row
        v-if="category && category.lang"
        align="middle"
        class="flex-baseline"
        style="margin-bottom: 15px"
      >
        <a-col :span="4" style="text-align: right; padding-right: 8px">
          {{ $t("Value") }}:
        </a-col>
        <a-col :span="20">
          <a-row align="middle" class="flex-baseline flex-wrap">
            <a-input v-model="form.text[getKey]" :placeholder="$t('Value')" />
          </a-row>
        </a-col>
      </a-row>
      <a-row v-else align="middle" class="flex-baseline" style="margin-bottom: 15px">
        <a-col :span="4" style="text-align: right; padding-right: 8px">
          {{ $t("Value") }}:
        </a-col>
        <a-col :span="20">
          <a-row align="middle" class="flex-baseline flex-wrap">
            <a-input v-model="form.value" :placeholder="$t('Value')" />
          </a-row>
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button key="back" @click="closeModal">{{ $t("Close") }}</a-button>
        <a-button key="save" type="primary" class="edit-btn" @click="createItem">{{
          $t("Save")
        }}</a-button>
      </template>
    </a-modal>
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      form: {
        title: {
          uz: "",
          oz: "",
          ru: "",
          en: "",
        },
        text: {
          uz: "",
          oz: "",
          ru: "",
          en: "",
        },
        value: "",
        category: "",
      },
      langTab: "oz",
      list: [],
      categoryList: [
        {
          id: 1,
          category: "telegram",
          name: {
            uz: "Telegram",
            oz: "Телеграм",
            ru: "Телеграм",
            en: "Telegram",
          },
          lang: false,
        },
        {
          id: 2,
          category: "instagram",
          name: {
            uz: "Instagram",
            oz: "Инстаграм",
            ru: "Инстаграм",
            en: "Instagram",
          },
          lang: false,
        },
        {
          id: 3,
          category: "youtube",
          name: {
            uz: "Youtube",
            oz: "Youtube",
            ru: "Youtube",
            en: "Youtube",
          },
          lang: false,
        },
        {
          id: 4,
          category: "facebook",
          name: {
            uz: "Facebook",
            oz: "Facebook",
            ru: "Facebook",
            en: "Facebook",
          },
          lang: false,
        },
        {
          id: 5,
          category: "phone",
          name: {
            uz: "Telefon raqam",
            oz: "Телефон рақам",
            ru: "Телефонный номер",
            en: "Phone number",
          },
          lang: false,
        },
        {
          id: 6,
          category: "email",
          name: {
            uz: "Elektron pochta",
            oz: "Электрон почта",
            ru: "Эл. адрес",
            en: "Email",
          },
          lang: false,
        },
        {
          id: 7,
          category: "address",
          name: {
            uz: "Manzil",
            oz: "Манзил",
            ru: "Адрес",
            en: "Address",
          },
          lang: true,
        },
        {
          id: 8,
          category: "google_play",
          name: {
            uz: "Mobil ilova (Google Play)",
            oz: "Мобил илова (Google Play)",
            ru: "Мобильное приложение (Google Play)",
            en: "Mobile app (Google Play)",
          },
          lang: false,
        },
      ],
      category: {},
      loading: false,
      columns: [
        {
          width: "60px",
          key: "id",
          title: "Id",
          scopedSlots: { customRender: "id" },
          fixed: "left",
        },
        {
          title: this.$t("TagsColTitle"),
          key: "title",
          scopedSlots: { customRender: "title" },
          width: "20%",
        },
        {
          title: this.$t("Type"),
          key: "category",
          scopedSlots: { customRender: "category" },
          width: "20%",
        },
        {
          title: this.$t("Value"),
          key: "value",
          scopedSlots: { customRender: "value" },
          width: "20%",
        },
        {
          title: "",
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      modalVisible: false,
    };
  },
  computed: {
    getKey() {
      return this.langTab;
    },
  },
  async created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const { data } = await this.$store.dispatch("contacts/getContact");
      this.list = data.results || [];
      this.loading = false;
    },
    async createItem() {
      try {
        if (this.category && this.category.lang) {
          delete this.form.value;
        } else this.form.text;
        if (this.form.id) {
          let { data } = await this.$api.put(
            `/admin/contacts/${this.form.id}`,
            this.form
          );
          if (data.status) {
            await this.fetchData();
            this.$message.success("Успешно");
            this.closeModal();
          } else {
            this.$message.error(data.message);
          }
        } else {
          let { data } = await this.$api.post("/admin/contacts/create", this.form);
          if (data.status) {
            await this.fetchData();
            this.$message.success("Успешно");
            this.closeModal();
          } else {
            this.$message.error(data.message);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    closeModal() {
      this.modalVisible = false;
      this.category = {};
      this.form = {
        title: {
          uz: "",
          oz: "",
          ru: "",
          en: "",
        },
        text: {
          uz: "",
          oz: "",
          ru: "",
          en: "",
        },
        value: "",
        category: "",
      };
    },
    async removeItem(id) {
      try {
        const { data } = await this.$api.delete(`/admin/contacts/${id}`);
        if (data.status) {
          this.$message.success("Успешно удалена");
          this.fetchData();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async updateItem(id) {
      try {
        const { data } = await this.$api.get(`/admin/contacts/${id}`);
        if (data.status) {
          this.form = data.data;
          this.onChangeCategory(this.form.category);
          this.modalVisible = true;
        } else {
          this.$message.error(data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    onChangeCategory(category) {
      this.category = this.categoryList.find((el) => el.category == category);
    },
    findCategory(category) {
      return this.categoryList.find((el) => el.category == category).name[
        this.$i18n.locale
      ];
    },
  },
};
</script>
